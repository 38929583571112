import React from 'react';
import PropTypes from 'prop-types';
import styles from './Banner.module.sass';
import Button from '../../../../ui_kit/button/Button';

function UniversalBanner(props) {
  const {
    url, urlText, hideCtaButton, title, description, video, poster,
    posterWebp, placeholder, placeholderWebp, color, className,
  } = props;

  return (
    <div className={className}>
      <div className={styles['video-wrapper']}>
        <div className={styles['content-wrapper']}>
          <div className={styles.content} style={{ color }}>
            { title && (
              <div className={styles.title}>
                {title.split(/\r?\n/).map((line, index) => (
                  // eslint-disable-next-line react/no-array-index-key
                  <React.Fragment key={index}>
                    {line}
                    <br />
                  </React.Fragment>
                ))}
              </div>
            )}
            <div className={styles.description}>
              { description && (
                <>
                  {description}
                  <br />
                </>
              )}
              { !hideCtaButton && (
                <Button
                  className={styles.button}
                  size="small"
                  color="white"
                  text={urlText}
                  href={url}
                />
              )}
            </div>
          </div>
        </div>
      </div>
      <a href={url} className={styles.video}>
        { video && (
          <video
            className="lazyload blur"
            data-src={video}
            autoPlay
            playsInline
            loop
            muted
            width="100%"
            poster={placeholderWebp}
            data-poster={posterWebp}
          />
        )}
        { !video && (
          <picture>
            <source data-srcset={posterWebp} srcSet={placeholderWebp} type="image/webp" />
            <img className="lazyload blur" data-src={placeholder} src={poster} alt={title} />
          </picture>
        )}
      </a>
    </div>
  );
}

UniversalBanner.propTypes = {
  url: PropTypes.string.isRequired,
  urlText: PropTypes.string.isRequired,
  hideCtaButton: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  poster: PropTypes.string.isRequired,
  posterWebp: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  placeholderWebp: PropTypes.string.isRequired,
  video: PropTypes.string,
  color: PropTypes.string.isRequired,
  className: PropTypes.string.isRequired,
};

UniversalBanner.defaultProps = {
  video: null,
};

function Banner(props) {
  const {
    banner: {
      url, url_text: urlText, title, description, color,
      hide_cta_button: hideCtaButton,
      mobile_poster_url: mobilePosterUrl,
      mobile_poster_url_webp: mobilePosterUrlWebp,
      desktop_poster_url: desktopPosterUrl,
      desktop_poster_url_webp: desktopPosterUrlWebp,
      mobile_video_url: mobileVideoUrl,
      desktop_video_url: desktopVideoUrl,
      mobile_placeholder_url: mobilePlaceholderUrl,
      desktop_placeholder_url: desktopPlaceholderUrl,
      mobile_placeholder_url_webp: mobilePlaceholderUrlWebp,
      desktop_placeholder_url_webp: desktopPlaceholderUrlWebp,
    }, mobile, desktop,
  } = props;

  return (
    <>
      { mobile && (
        <UniversalBanner
          url={url}
          urlText={urlText}
          hideCtaButton={hideCtaButton}
          title={title}
          description={description}
          video={mobileVideoUrl}
          poster={mobilePosterUrl}
          posterWebp={mobilePosterUrlWebp}
          placeholder={mobilePlaceholderUrl}
          placeholderWebp={mobilePlaceholderUrlWebp}
          color={color}
          className={styles.mobile}
        />
      )}
      { desktop && (
        <UniversalBanner
          url={url}
          urlText={urlText}
          hideCtaButton={hideCtaButton}
          title={title}
          description={description}
          video={desktopVideoUrl}
          poster={desktopPosterUrl}
          posterWebp={desktopPosterUrlWebp}
          placeholder={desktopPlaceholderUrl}
          placeholderWebp={desktopPlaceholderUrlWebp}
          color={color}
          className={styles.desktop}
        />
      )}
    </>
  );
}

Banner.propTypes = {
  banner: PropTypes.shape({
    url: PropTypes.string,
    url_text: PropTypes.string,
    hide_cta_button: PropTypes.bool,
    title: PropTypes.string,
    description: PropTypes.string,
    mobile_poster_url: PropTypes.string,
    desktop_poster_url: PropTypes.string,
    mobile_poster_url_webp: PropTypes.string,
    desktop_poster_url_webp: PropTypes.string,
    mobile_video_url: PropTypes.string,
    desktop_video_url: PropTypes.string,
    mobile_placeholder_url: PropTypes.string,
    desktop_placeholder_url: PropTypes.string,
    mobile_placeholder_url_webp: PropTypes.string,
    desktop_placeholder_url_webp: PropTypes.string,
    color: PropTypes.string,
  }).isRequired,
  mobile: PropTypes.bool,
  desktop: PropTypes.bool,
};

Banner.defaultProps = {
  mobile: false,
  desktop: false,
};

export default Banner;
