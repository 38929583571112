import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './Banners.sass';
import Banner from './banner/Banner';

function Banners(props) {
  const { banners } = props;

  const [isClient, setIsClient] = useState(false);

  useEffect(() => {
    setIsClient(true);
  }, []);

  const firstBanner = banners[0];

  return (
    <div className="banners-slider">
      { !isClient && firstBanner && (
        <>
          <Banner banner={firstBanner} mobile />
          <Banner banner={firstBanner} desktop />
        </>
      )}
      { isClient && (
        <>
          <div className="mobile">
            <Slider
              slidesToShow={1}
              slidesToScroll={1}
              arrows={false}
              adaptiveHeight
              dots
              autoplay
              autoplaySpeed={5000}
              speed={1000}
              infinite
            >
              {banners.map((banner) => (
                <Banner banner={banner} key={banner.id} mobile />
              ))}
            </Slider>
          </div>
          <div className="desktop">
            <Slider
              slidesToShow={1}
              slidesToScroll={1}
              arrows={false}
              adaptiveHeight
              dots
              autoplay
              autoplaySpeed={5000}
              speed={1000}
              infinite
            >
              {banners.map((banner) => (
                <Banner banner={banner} key={banner.id} desktop />
              ))}
            </Slider>
          </div>
        </>
      )}
    </div>
  );
}

Banners.propTypes = {
  banners: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
    }),
  ).isRequired,
};

export default Banners;
