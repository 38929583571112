import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import Banners from './components/banners/Banners';
import TopBanner from './components/top_banner/TopBanner';

const Instagram = React.lazy(() => import('./components/instagram/Instagram'));
const StickyCategories = React.lazy(() => import('./components/sticky_categories/StickyCategories'));

document.addEventListener('DOMContentLoaded', () => {
  const elementId = 'react-banners-component';
  const bannersElement = document.getElementById(elementId);
  const data = JSON.parse(document.querySelector(`script[data-dom-id="${elementId}"]`).textContent);
  const { banners } = data;
  if (banners.length > 0) {
    ReactDOM.hydrate(
      <Banners banners={banners} />,
      bannersElement,
    );
  } else {
    const topBannerElement = document.getElementById('top-banner');
    const { url } = topBannerElement.dataset;
    ReactDOM.render(
      <TopBanner url={url} />,
      topBannerElement,
    );
  }

  const instagramElement = document.getElementById('instagram');
  const { token } = instagramElement.dataset;
  ReactDOM.render(
    <Suspense fallback="">
      <Instagram token={token} />
    </Suspense>,
    instagramElement,
  );

  ReactDOM.render(
    <Suspense fallback="">
      <Provider store={window.store}>
        <StickyCategories />
      </Provider>
    </Suspense>,
    document.getElementById('react-sticky-categories'),
  );
});
