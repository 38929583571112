import React from 'react';
import PropTypes from 'prop-types';
import mobilePoster from '../../../../images/pages/homepage/banners/hibiscus/mobile.jpg';
import desktopPoster from '../../../../images/pages/homepage/banners/hibiscus/desktop.jpg';
import Banner from '../banners/banner/Banner';

const DESKTOP_VIDEO_URL = 'https://d1av6cg597bk68.cloudfront.net/videos/hibiscus/desktop.mp4';
const MOBILE_VIDEO_URL = 'https://d1av6cg597bk68.cloudfront.net/videos/hibiscus/mobile.mp4';

function TopBanner(props) {
  const { url } = props;

  const banner = {
    url,
    url_text: 'Shop New Hibiscus Unspottable Correcting Oil.',
    title: 'Innovation never looked\nthis good',
    description: 'We make ingenious solutions for your authentic needs.',
    mobile_poster: {
      url: mobilePoster,
    },
    desktop_poster: {
      url: desktopPoster,
    },
    mobile_video: {
      url: MOBILE_VIDEO_URL,
    },
    desktop_video: {
      url: DESKTOP_VIDEO_URL,
    },
  };

  return (
    <Banner banner={banner} />
  );
}

TopBanner.propTypes = {
  url: PropTypes.string.isRequired,
};

export default TopBanner;
